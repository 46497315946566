import * as React from 'react';
import classnames from 'classnames';
import { IPreloaderProps } from '../Preloader.types';
import { getDataAttributes } from '../../../core/commons/utils';
import style from './style/Preloader.scss';

const Preloader: React.FC<IPreloaderProps> = props => {
  const { enabled, dark, className } = props;

  return (
    <div
      {...getDataAttributes(props)}
      className={classnames(className, style.preloader, {
        [style.enabled]: !!enabled,
        [style.dark]: !!dark,
      })}
    />
  );
};

export default Preloader;
