export const translationFeature = 'dialogMixinTranslations';

export const VerificationCodeDialogTranslationKeys = {
  title: 'SMRVerificationCode_Dialog_title',
  paragraph: 'SMRVerificationCode_Dialog_paragraph',
  footerParagraph: 'SMRVerificationCode_Dialog_footerParagraph',
  footerParagraphLink: 'SMRVerificationCode_Dialog_footerParagraph_Link',
  emailParagraph: 'SMRVerificationCode_Dialog_to_email',
  submitButton: 'SMRVerificationCode_Dialog_submitButton',
  generalErr: 'SMForm_Error_General_Err',
  resendErr: 'SMForm_resendEmail',
  verificationErr: 'SMForm_Error_EMAIL_VERIFICATION_FAILED',
  Code_Not_Found: 'SMRVerificationCode_Dialog_Code_NOT_FOUND',
  Code_Bad_Code: 'SMRVerificationCode_Dialog_Code_BAD_CODE',
  To_Many_Requests: 'SMForm_Error_19901',
};

export const codeInput = 'codeInput';
export const testIds = {
  headline: 'verificationCode.headline',
  resendVerificationCodeEmailButton: 'resendVerificationCodeEmailButton',
};
